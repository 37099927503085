import Image from "next/image";
import { COUNTRY_CODES } from "@/types/common.types";
import styles from "./PostCard.module.scss";
import {
  AstroLiveCardDataType,
  LiveRoomData,
  RoomResponse,
  UserProfileData,
} from "../../Enum";
import { Images } from "@/utils/imageRelativePaths";
import CountryIcon from "@/common/components/CountryIcon/CountryIcon";
import ImageWithFallback from "@/common/components/ImageWithFallback";

interface PostCardProps extends RoomResponse {
  className?: string;
  onClick?: (roomId?: number | undefined) => void;
  liveRoom: LiveRoomData;
  viewType?: string,
  userProfile: UserProfileData;
}

const PostCard = ({
  className = "",
  userProfile: { level, country, userId },
  liveRoom: {
    name,
    membersCount,
    languages: [firstLanguage],
    roomIcon,
  },
  onClick,
}: PostCardProps) => {
  return (
    <div
      className={`${styles.postWrapper} ${className}`}
      onClick={() => onClick && onClick()}
    >
      <ImageWithFallback
        className={styles.postImage}
        src={roomIcon}
        fill
        alt=""
      />
      <div className={styles.postInfo}>
        <div className={styles.count}>
          <Image
            className={styles.postImage}
            src={Images.IC_VIEWERS}
            width={15}
            height={15}
            alt="viewers-icon"
          />
          {membersCount}
        </div>
        <div>
          <div>{firstLanguage}</div>
          <div className={styles.name}>{name}</div>
          <div className={styles.userStatsWrapper}>
            {country && (
              <CountryIcon
                countryCode={COUNTRY_CODES[
                  country as keyof typeof COUNTRY_CODES
                ]?.toUpperCase()}
              />
            )}
            {/* <Image
              className={styles.postImage}
              src={`${LEVEL_ICON}/${level}.svg`}
              width={45}
              height={18}
              alt="viewers-icon"
            /> */}
          </div>
          {/* <span>{data.topic.join(", ")}</span> */}
        </div>
      </div>
    </div>
  );
};

export default PostCard;
