import {
  LiveDataType,
  ROOM_TYPE,
  RowItem,
  sessionType,
} from "../../types/common.types";
import { getNonAuthToken } from "../../services/common.service";
import { getRoomFeed } from "../../services/rooms.service";
import {
  RoomReqBody,
  AstroFreeKundliPlanetResponse,
  AstroFreeKundliKpResponse,
  AstroFreeKundliAshtakvargaResponse,
  AstroFreeKundliBasicResponse,
  AstroFreeKundliDashaResponse,
  FreeKundliAshtakvargaItem,
} from "./Enum";

export async function GetLiveAstroData(): Promise<LiveDataType> {
  return new Promise(async (resolve, reject) => {
    const liveData: LiveDataType = { astrologers: [], liveSessions: [] };
    try {
      const nonAuthToken = await getNonAuthToken();
      const roomType = "live",
        callType = "call";
      const roomReqBody = generateRoomReqBody(
        roomType as ROOM_TYPE,
        "Astrology",
        "All"
      );
      const res = await getRoomFeed({ token: nonAuthToken }, roomReqBody);
      liveData["liveSessions"] = res.data;

      const callReqBody = generateRoomReqBody(
        callType as ROOM_TYPE,
        "Astrology",
        "All"
      );
      // restrict size to 6
      callReqBody["size"] = 6;
      roomReqBody["size"] = 6;

      const callRes = await getRoomFeed({ token: nonAuthToken }, callReqBody);
      liveData["astrologers"] = callRes.data;
      resolve(liveData);
    } catch (error) {
      console.log(error);
    } finally {
      resolve(liveData);
    }
  });
}

export const generateRoomReqBody = (
  roomType: ROOM_TYPE = ROOM_TYPE.LIVE,
  category?: string,
  subCategory?: string
): RoomReqBody => ({
  ...INITIAL_ROOM_REQ_BODY,
  roomType,
  category,
  subCategory,
});

const FEED_MAX_SIZE = 10;
const INITIAL_ROOM_REQ_BODY: Omit<RoomReqBody, "type" | "roomType"> = {
  start: 0,
  size: FEED_MAX_SIZE,
};

declare global {
  interface Window {
    gtag: (key: string, targetId: string, config: any) => void;
    Razorpay: any;
  }
}

export const logEvent = (event: string, data: object): void => {
  // window.gtag("event", event, {
  //   ...data,
  // });
};

export const getFormatDateFromPrediction1 = (date: string) => {
  const [day, month, year] = date.split("-").map(Number);
  return new Date(year, month - 1, day);
};

export const getFormatDateFromPrediction2 = (date: string) => {
  const [year, month, day] = date.split("-").map(Number);
  return new Date(year, month - 1, day);
};

export const TransformAstroBasicData = (res: AstroFreeKundliBasicResponse) => {
  return {
    basic: Object.keys(res?.basic)?.map((key) => ({
      data: [key, res?.basic?.[key]],
    })),
    panchang: Object.keys(res?.panchang)?.map((key) => ({
      data: [key, res?.panchang?.[key]],
    })),
    avakadha: Object.keys(res?.avakadha)?.map((key) => ({
      data: [key, res?.avakadha?.[key]],
    })),
  };
};

export const TransformAstroPlanetsData = (
  res: AstroFreeKundliPlanetResponse
) => {
  return {
    sign: [
      {
        data: ["Planet", "Sign", "Sign Lord", "Degree", "House"],
      },
      ...res?.sign?.map(({ planet, sign, signLord, degree, house }) => ({
        data: [planet, sign, signLord, degree.toFixed(2), house],
      })),
    ],
    nakshatra: [
      { data: ["Planet", "Nakshatra", "Naksh Lord", "House"] },
      ...res?.nakshatra?.map(({ name, nakshatra, nakshatraLord, house }) => ({
        data: [name, nakshatra, nakshatraLord, house],
      })),
    ],
  };
};

export const TransformAstroKpData = (res: AstroFreeKundliKpResponse) => {
  return {
    chartImg: res?.chartImg,
    kpData: {
      planets: [
        {
          data: [
            "Planet",
            "Sign",
            "Cusp",
            "Sign Lord",
            "Star Lord",
            "Sub Lord",
          ],
        },
        ...res?.kpData?.planets?.map(
          ({
            planet_name,
            sign_name,
            house,
            sign_lord,
            nakshatra_lord,
            sub_lord,
          }) => ({
            data: [
              planet_name,
              sign_name,
              house,
              sign_lord,
              nakshatra_lord,
              sub_lord,
            ],
          })
        ),
      ],
      cusps: [
        {
          data: [
            "Cusp",
            "Sign",
            "Degree",
            "Sign Lord",
            "Star Lord",
            "Sub Lord",
          ],
        },
        ...res?.kpData?.houses?.map(
          ({
            sign_name,
            full_degree,
            sign_lord,
            nakshatra_lord,
            sub_lord,
          }) => ({
            data: [
              "house",
              sign_name,
              full_degree?.toFixed(2),
              sign_lord,
              nakshatra_lord,
              sub_lord,
            ],
          })
        ),
      ],
    },
  };
};

export const TransformAstroAshtakvargaData = (
  res: AstroFreeKundliAshtakvargaResponse
) => {
  const colVal = Object.keys(Object.values(res?.ashtak_points ?? {})?.[0]);
  let rowArr: RowItem[] = [];
  Object.keys(res?.ashtak_points ?? {})?.map((key) => {
    colVal?.map((colItem, idx) => {
      const currVal =
        res?.ashtak_points?.[key]?.[
          colItem as keyof FreeKundliAshtakvargaItem
        ] ?? "";
      if (rowArr[idx]) {
        rowArr[idx].data.push(currVal);
      } else
        rowArr[idx] = {
          data: [colItem, currVal],
        };
    });
  });
  return [
    {
      data: ["", ...Object.keys(res?.ashtak_points)],
    },
    ...rowArr,
  ];
};

export const TransformAstroDashaData = (res: AstroFreeKundliDashaResponse) => {
  return {
    vimshottari: [
      {
        data: Object.keys(res?.vimshottari?.[0]),
      },
      ...res?.vimshottari?.map((item) => ({
        data: Object.values(item),
      })),
    ],
    yogini: [
      {
        data: Object.keys(res?.yogini?.[0]),
      },
      ...res?.yogini?.map((item) => ({
        data: Object.values(item),
      })),
    ],
  };
};
