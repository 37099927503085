import styles from "./CountryIcon.module.scss";

interface CountryIconProps {
  classname?: string;
  countryCode: string;
}

const CountryIcon = ({
  classname = "",
  countryCode,
}:CountryIconProps) => {
  return (
    <div
      className={`${styles.countryWrapper} ${styles[countryCode]} ${classname}`}
    ></div>
  );
};

export default CountryIcon;
